@import '../../scss/config';

.slides-details-modal {
  .slide-img-wrap {
    margin-bottom: 22px;
    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }
  .modal-main-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;

    .custom-render-info-wrap {
      background-color: $main-bg-color;
      border-radius: 5px 20px 5px 20px;
      padding: 10px 22px;
      .info-label {
        color: $main-app-color;
        font-size: 14px;
      }
      .info-value {
        color: $main-text-color;
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }
}
