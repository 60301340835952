@import '../../scss/config';

.app-layout {
  display: flex;
  background-color: #fff;
  min-height: 100vh;
  .layout-content {
    flex: 1;
    background-color: #f9f8f8;
    min-height: 100vh;
    & > [class$='page'] {
      min-height: 100vh;
    }

    .layout-header {
      height: 62px;
      background-color: #fff;
      padding: 8px 12px;
      display: grid;
      gap: 12px;
      grid-auto-flow: column;
      align-items: center;
      .page-main-title {
        color: $main-app-color;
        font-size: 30px;
        font-weight: bold;
      }
      .profile-menu-btn {
        justify-self: end;
        display: flex;
        align-items: center;
        min-height: 44px;
        background-color: transparent;
        gap: 8px;
      }
      .title-icon-text-wrap {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
