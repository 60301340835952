@import '../../scss/config';

.slides-details-modal {
  .slide-img-wrap {
    margin-bottom: 22px;
    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }
  .modal-main-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;

    .custom-render-info-wrap {
      background-color: $main-bg-color;
      border-radius: 5px 20px 5px 20px;
      padding: 10px 22px;
      .info-label {
        color: $main-app-color;
        font-size: 14px;
      }
      .info-value {
        color: $main-text-color;
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }

  .service-images-ul-warp {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px dashed rgba(#000, 0.2);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    align-items: center;
    .img-wrap {
      background-color: #eee;
      padding: 4px;
    }
    img {
      max-width: 100%;
      border-radius: 2px;
    }
  }
}
