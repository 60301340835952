@import '../../scss/config';

.main-app-footer {
  background-color: $main-app-color;
  padding: 4px 0;
  text-align: center;
  font-size: 18px;
  color: #fff;

  .footer-content {
    display: grid;
    gap: 12px;
    @include mediaLg {
      grid-template-columns: auto 1fr auto;
    }
    align-items: center;
    .footer-logo {
      img {
        // max-width: 100%;
        max-width: 182px;
      }
    }
  }
  .footer-social-links {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    a {
      display: grid;
      place-items: center;
      width: 42px;
      height: 42px;
      background-color: #fff;
      border-radius: 50%;
    }
    img {
      width: 22px;
    }
  }
}
