@import '../../scss/config';

.signin-page {
  .signin-form-section {
    display: grid;
    place-items: center;
    padding-top: 42px;
    min-height: 100vh;
  }
}
