.social-media-links-form {
  background-color: #fff;
  padding: 22px 28px !important;
  max-width: 600px;
  .form-body {
    display: grid;

    .all-fields-wrap {
      display: grid;
      gap: 12px;
    }
  }
}
