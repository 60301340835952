@import '../../scss/config';

.custom-image {
  background-color: #eee;
  border-radius: 5px;
  display: grid;
  width: 100%;
  .ant-skeleton-element {
    width: 100%;
    .ant-skeleton-image {
      width: 100%;
      height: 100%;
      background-color: transparent;
      & > svg {
        fill: rgba(#000, 0.6);
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 220px;
      }
    }
  }
}
