.main-appbar-full-warpper {
  .main-appbar-logo {
    display: flex;
    img {
      max-width: 100%;
      height: 58px;
      margin: 2px 0;
    }
  }
  .main-appbar-logo-menu-item {
    height: 62px !important;
    line-height: 62px !important;
    margin-top: 0 !important;
  }
}
