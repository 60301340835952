.app-settings-form {
  background-color: #fff;
  padding: 22px 28px !important;
  max-width: 1200px;
  .form-body {
    display: grid;

    .all-fields-wrap {
      display: grid;
      gap: 12px;

      .basic-info-fields-wrap,
      .editors-wrap,
      .social-fields-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 12px;
      }
      .editors-wrap {
        gap: 18px 12px;
      }

      // .file-inputs-wrap {
      //   grid-column: 1 / -1;
      // }
    }
  }
}
