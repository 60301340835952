@import '../../scss/config';
.profile-page {
  .page-title {
    h2 {
      font-size: 28px;
      text-align: center;
      @include mediaLg {
        font-size: 42px;
      }
    }
    p {
      font-size: 18px;
    }
  }
  .page-head-wrap {
    display: grid;
    grid-template-columns: 2.9fr 1fr;
    grid-gap: 22px;
    .search-accounts-wrap {
      display: grid;
      align-content: space-between;
    }
  }

  .img-form-wrap {
    padding-bottom: 32px;
    display: grid;
    max-width: 632px;
    margin: auto;
    margin-top: 32px;
    & > img {
      align-self: center;
      // margin-top: 122px;
    }
  }
}
